import React from "react";
import { Link } from "react-router-dom";
import NarvalLogo from '../../assets/narval.svg';
import { NarvalEmail } from "../Email";

const Footer = () => {
    const scrollUp = () => {
        window.scrollTo(0, 0);
    };
    return (
        <>
            <footer className='container flex flex-wrap max-w-screen-2xl h-auto m-auto p-8 lg:pb-16 lg:pt-24'>
                    <div className="md:w-full lg:w-7/12 h-auto p-2 lg:px-8">
                        <Link to='/' onClick={scrollUp}>
                            <span className='pt-2'>
                                <img src={NarvalLogo} alt='Narval logo' className='h-8'></img>
                            </span>
                        </Link>
                        <p className='mt-7 pl-1 display2-text'>Disrupting antibody drugs</p>
                    </div>
                    <div className='h-auto mt-5 p-2 sec-name lg:w-5/12'>
                        <div className='pr-4 pb-8'>
                            <p>TECHNOLOGY</p>
                            <Link to='/technology' onClick={scrollUp}>
                                <button className='relative group overflow-hidden pr-6 h-12 rounded-r-full flex space-x-2 items-center
                                hover:bg-gradient-to-l from-cyannarv-500/25'>
                                    <span className='relative font-secondary font-semibold text-md text-cyannarv-300'>LEARN ABOUT AMP</span>
                                    <div className='flex items-center -space-x-3 translate-x-3'>
                                        <div className='w-4 h-[2.8px] rounded-full bg-cyannarv-300 origin-left scale-x-0 transition duration-300
                                        group-hover:scale-x-100'></div>
                                        <svg xmlns='http://www.w3.org/2000/svg'
                                            className='h-6 w-6 stroke-cyannarv-300 -translate-x-1 transition
                                            duration-300 group-hover:translate-x-0'
                                            fill='none'
                                            viewBox='0 0 24 24'
                                            stroke='currentColor'
                                            strokeWidth='3'>
                                            <path strokeLinecap='round' strokeLinejoin='round' d='M9 5l7 7-7 7'/>
                                        </svg>
                                    </div>
                                </button>
                            </Link>
                        </div>
                        <div className='pr-4 pb-8'>
                            <p>CONTACT</p>
                            <Link to='/contact' onClick={scrollUp}>
                                <button className='relative group overflow-hidden pr-6 h-12 rounded-r-full flex space-x-2 items-center
                                    hover:bg-gradient-to-l from-cyannarv-500/25'>
                                        <span className='relative font-secondary font-semibold text-md text-cyannarv-300'>GET IN TOUCH</span>
                                        <div className='flex items-center -space-x-3 translate-x-3'>
                                            <div className='w-4 h-[2.8px] rounded-full bg-cyannarv-300 origin-left scale-x-0 transition duration-300
                                            group-hover:scale-x-100'></div>
                                            <svg xmlns='http://www.w3.org/2000/svg'
                                                className='h-6 w-6 stroke-cyannarv-300 -translate-x-1 transition
                                                duration-300 group-hover:translate-x-0'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                stroke='currentColor'
                                                strokeWidth='3'>
                                                <path strokeLinecap='round' strokeLinejoin='round' d='M9 5l7 7-7 7'/>
                                            </svg>
                                        </div>
                                </button>
                            </Link>
                        </div>
                        <div className='pr-4 pb-8'>
                            <p>OR SEND US AN EMAIL TO:</p>
                            <p className='font-secondary font-semibold text-xl text-cyannarv-300 pt-2'><NarvalEmail/></p>
                        </div>
                        <p className='font-light pt-0'>© 2024 Narval. All rights reserved.</p>
                        <p className='font-light text-sm'><a href='https://3d.nih.gov/entries/3DPX-012103' target='_blank' rel='license' className='underline'>VEGFR</a> Autogenerated by NIH3D</p>
                    </div>
            </footer>
        </>
    )
};

export default Footer;