import React, {useRef, useState} from "react";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import credential from "./credential";




const ContactForm = () => {

    const recaptcha = useRef(null);
    const [captchaState, setCaptchaState] = useState(false);
    const [sentFormMessage, vanishFormMessage] = useState(false);

    const onChange = () => {
        if(recaptcha.current.getValue()) {
            setCaptchaState(false);
        }
    };

    return (

            <Formik
                initialValues={{
                    subject:'Email from Narval website',
                    email:'',
                    fullName:'',
                    company:'',
                    message:''
                }}

                validationSchema={Yup.object({
                    email: Yup.string()
                        .required('*Required field')
                        .email('Please enter a valid email address'),
                    fullName: Yup.string()
                        .required('*Required field'),
                    company: Yup.string()
                        .required('*Required field'),
                    message: Yup.string()
                        .required('*Required field'),
                })}

                onSubmit={(values, {resetForm}) => {
                    if(recaptcha.current.getValue()) {
                        axios({
                            url:'./contact.php',
                            method:'POST',
                            headers: {
                                'content-type': 'text/html; charset=UTF-8',
                            },
                            data:JSON.stringify(values)
                        })
                        .then(
                            () => {
                                setCaptchaState(false);
                            })
                        .then ( () => {
                            resetForm();
                            vanishFormMessage(true);
                            setTimeout(() => vanishFormMessage(false), 8000);
                            })
                        .catch( error => {
                            alert('Sorry, there was a problem. Try again later please.')
                            console.log(error)
                        })

                    } else {
                        setCaptchaState(true);
                        console.log('no hay captcha' + captchaState);
                    }
                }}
            >

                {({errors})=>(
                    <Form>
                        <div className='w-12/12 mt-5 mb-5'>
                            <label htmlFor='email' className='block mb-2'>Email</label>
                            <div className='w-full'>
                                <Field
                                name='email'
                                type='email'
                                placeholder="E.g. name@email.com"
                                className='w-full h-8 rounded-sm border-0 p-2 ring-1 ring-inset ring-zinc font-secondary text-sm'>
                                </Field>
                                <ErrorMessage name='email' component={() => (<p className='pt-2 font-secondary text-red'>{errors.email}</p>)}/>
                            </div>
                        </div>
                        <div className='w-12/12 mt-5 mb-5'>
                            <label htmlFor='fullName' className='block mb-2'>Full Name</label>
                            <div>
                                <Field
                                name='fullName'
                                type='text'
                                placeholder="First Name and Last Name"
                                className='w-full h-8 rounded-sm border-0 p-2 ring-1 ring-inset ring-zinc font-secondary text-sm'>
                                </Field>
                                <ErrorMessage name='fullName' component={() => (<p className='pt-2 text-sm font-secondary text-red'>{errors.fullName}</p>)}/>
                            </div>
                        </div>
                        <div className='w-12/12 mt-5 mb-5'>
                            <label htmlFor='company' className='block mb-2'>Company Name / Organization</label>
                            <div>
                                <Field
                                name='company'
                                type='text'
                                placeholder="Your Company or Organization"
                                className='w-full h-8 rounded-sm border-0 p-2 ring-1 ring-inset ring-zinc font-secondary text-sm'>
                                </Field>
                                <ErrorMessage name='company' component={() => (<p className='pt-2 text-sm font-secondary text-red'>{errors.company}</p>)}/>
                            </div>
                        </div>
                        <div className='w-12/12 mt-5 mb-5'>
                            <label htmlFor='message' className='block mb-2'>Message</label>
                            <div className="4/4">
                                <Field
                                name='message'
                                as='textarea'
                                placeholder='Write your message here'
                                rows={6}
                                className='w-full rounded-sm border-0 p-2 ring-1 ring-inset ring-zinc font-secondary text-sm font-secondary text-sm leading-6'
                                ></Field>
                                <ErrorMessage name='message' component={() => (<p className='pt-2 text-sm font-secondary text-red'>{errors.message}</p>)}/>
                            </div>
                        </div>

                        <div>
                            <ReCAPTCHA
                                ref={recaptcha}
                                sitekey={`${credential.siteKey}`}
                                onChange={onChange}
                            />
                            {captchaState !== false && <p className='font-secondary text-red text-sm'>*Recaptcha is required</p>}
                        </div>

                        <div className='w-12/12 mt-8 mb-5'>
                            <button type='submit' className='relative group overflow-hidden pr-6 h-12 rounded-r-full flex space-x-2 items-center
                            hover:bg-gradient-to-l from-cyannarv-500/25'>
                                <span className='relative font-secondary font-semibold text-md text-cyannarv-300'>SEND MESSAGE</span>
                                <div className='flex items-center -space-x-3 translate-x-3'>
                                    <div className='w-4 h-[2.8px] rounded-full bg-cyannarv-300 origin-left scale-x-0 transition duration-300
                                    group-hover:scale-x-100'></div>
                                    <svg xmlns='http://www.w3.org/2000/svg'
                                        className='h-6 w-6 stroke-cyannarv-300 -translate-x-1 transition
                                        duration-300 group-hover:translate-x-0'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        stroke='currentColor'
                                        strokeWidth='3'>
                                        <path strokeLinecap='round' strokeLinejoin='round' d='M9 5l7 7-7 7'/>
                                    </svg>
                                </div>
                            </button>
                            {sentFormMessage &&
                            <div className='w-full h-full rounded-md my-5 bg-white font-secondary font-light text-xl'>
                                <p className='text-zinc p-5'>Your message was successfully sent!</p>
                            </div>}
                        </div>
                    </Form>
                )}
            </Formik>
    );
};

export default ContactForm;
//{/*} */}