import React from "react";
import bar from '../../assets/bar.svg';
import statBar from '../../assets/stat-bar.svg';

const PipelineTabs = () =>  (
    <>
        <table className='sm:hidden w-full mt-10 divide-y-2 font-primary text-cyannarv-500'>
                    <thead className=''>
                        <tr className='text-left'>
                            <th className='py-6'>PRODUCT</th>
                            <th className='py-6'>AMP-1</th>
                        </tr>
                    </thead>
                    <tbody className='text-left'>
                        <tr>
                            <td className='px-2 pt-8 font-semibold'>PROGRAM</td>
                            <td className='px-2 pt-8'>Internal</td>
                        </tr>
                        <tr>
                            <td className='px-2 pt-4 font-semibold'>TARGET</td>
                            <td className='px-2 pt-4'>VEGF</td>
                        </tr>
                        <tr>
                            <td className='px-2 pt-4 font-semibold'>INDICATION</td>
                            <td className='px-2 pt-4'>Diabetic Retinopathy</td>
                        </tr>
                        <tr>
                            <td className='px-2 pt-4 font-semibold'>STAGE</td>
                            <td className='px-2 pt-4'>Development</td>
                        </tr>
                    </tbody>
        </table>
        <div className='sm:hidden w-full mt-7'>
            <img src={bar} alt='Ready for preclinical' className='h-10'></img>
        </div>

        <table className='sm:hidden w-full mt-10 divide-y-2 font-primary text-cyannarv-500'>
                    <thead className=''>
                        <tr className='text-left'>
                            <th className='py-6'>PRODUCT</th>
                            <th className='py-6'>AMP-6</th>
                        </tr>
                    </thead>
                    <tbody className='text-left'>
                        <tr>
                            <td className='px-2 pt-8 font-semibold'>PROGRAM</td>
                            <td className='px-2 pt-8'>Internal</td>
                        </tr>
                        <tr>
                            <td className='px-2 pt-4 font-semibold'>TARGET</td>
                            <td className='px-2 pt-4'>Confidential</td>
                        </tr>
                        <tr>
                            <td className='px-2 pt-4 font-semibold'>INDICATION</td>
                            <td className='px-2 pt-4'>Confidential</td>
                        </tr>
                        <tr>
                            <td className='px-2 pt-4 font-semibold'>STAGE</td>
                            <td className='px-2 pt-4'>Development</td>
                        </tr>
                    </tbody>
        </table>
        <div className='sm:hidden w-full mt-7'>
            <img src={statBar} alt='Early Development' className='h-10'></img>
        </div>

        <table className='hidden sm:table w-full sm:w-screen divide-y-2 mt-6 mb-20 font-primary lg:text-xl text-cyannarv-500'>
                    <thead className='divide-y-2'>
                        <tr className='text-left'>
                            <th className='px-2 py-10'>PRODUCT</th>
                            <th className='px-2 py-10'>PROGRAM</th>
                            <th className='px-2 py-10'>TARGET</th>
                            <th className='px-2 py-10'>INDICATION</th>
                            <th className='px-2 py-10'>DEVELOPMENT</th>
                            <th className='px-2 py-10'>PRECLINICAL</th>
                            <th className='px-2 py-10'>PHASE 0</th>
                            <th className='px-2 py-10'>PHASE 1</th>
                        </tr>
                    </thead>
                    <tbody className='divide-y-2'>
                        <tr>
                            <td className='px-2 py-10'>AMP-1</td>
                            <td className='px-2 py-10'>Internal</td>
                            <td className='px-2 py-10'>VEGF</td>
                            <td className='px-2 py-10'>Diabetic Retinopathy</td>
                            <td className='h-auto px-2 py-10'>
                                <img src={bar} alt='Ready for preclinical' className='h-7'></img>
                            </td>
                        </tr>
                        <tr>
                            <td className='px-2 py-10'>AMP-6</td>
                            <td className='px-2 py-10'>Internal</td>
                            <td className='px-2 py-10'>Confidential</td>
                            <td className='px-2 py-10'>Confidential</td>
                            <td className='h-auto px-2 py-10'>
                                <img src={statBar} alt='Early Development' className='h-7'></img>
                            </td>
                        </tr>
                    </tbody>
        </table>
    </>
);

export default PipelineTabs;