import React from 'react';
import { Link } from 'react-router-dom';
import hero from '../../assets/amp-vgef-surface.png';
import amp from '../../assets/amp-ab.png';

const Home = () => (
    <>
    <div className='bg-gradient-r'>
        <section className='container flex flex-wrap max-w-screen-2xl w-full h-screen md:h-auto m-auto p-8'>
            <div className='md:flex-1 lg:p-12 xl:pt-32 h-auto md:w-3/5 lg:w-6/12 mt-28 md:pl-5'>
                <p className='display1-text'>Pioneering</p>
                <h1 className='my-1 display2-text'>unconventional antibody drugs</h1>
                <h2 className='my-4 parr-text font-light'>
                    <strong className='font-bold'>Antibody Mimetic Proteins™</strong> to locally treat diseases in the eyes, lungs and skin.</h2>
                <Link to='/technology'>
                    <button className='relative group overflow-hidden pr-6 h-12 rounded-r-full flex space-x-2 items-center
                    hover:bg-gradient-to-l from-cyannarv-500/25'>
                        <span className='relative font-secondary font-semibold text-md text-cyannarv-300'>LEARN HOW</span>
                        <div className='flex items-center -space-x-3 translate-x-3'>
                            <div className='w-4 h-[2.8px] rounded-full bg-cyannarv-300 origin-left scale-x-0 transition duration-300
                            group-hover:scale-x-100'></div>
                            <svg xmlns='http://www.w3.org/2000/svg'
                                className='h-6 w-6 stroke-cyannarv-300 -translate-x-1 transition
                                duration-300 group-hover:translate-x-0'
                                fill='none'
                                viewBox='0 0 24 24'
                                stroke='currentColor'
                                strokeWidth='3'>
                                <path strokeLinecap='round' strokeLinejoin='round' d='M9 5l7 7-7 7'/>
                            </svg>
                        </div>
                    </button>
                </Link>
            </div>
            <div className='flex md:w-3/5 lg:w-6/12 lg:h-screen md:max-h-96 lg:max-h-144 md:mt-28 md:pl-10 lg:pr-10 sm:px-28 md:px-0 justify-center'>
                <img src={hero} alt='AMP & VEGFR surface' className='w-auto lg:max-h-144'></img>
            </div>
        </section>

        <section className='container flex flex-wrap md:flex-row-reverse max-w-screen-2xl w-full h-auto m-auto
            p-8 pt-32 md:pt-8 pb-10 lg:h-screen'>
                <div className='lg:mt-16 md:px-5 lg:p-16 md:w-6/12 lg:w-7/12'>
                    <p className='w-full sec-name pb-2 lg:text-right'>ABOUT US</p>
                    <h3 className='my-5 md:my-10 parr-text'>Narval is pioneering a new generation of antibody drugs using Synthetic Antibody Mimetic Proteins™
                        (AMP) <strong>delivered through mucosal or skin tissues to locally treat diseases</strong> in the eyes, lungs, and skin.
                        </h3>
                        <p className='mt-3 mb-10 highlighted-text'>We are creating <strong className='text-cyannarv-500'>affordable and easy to use antibody drugs
                         without systemic side effect risks</strong>, increasing access to treatment for millions.</p>
                </div>
                <div className='my-2 md:mt-16 md:w-6/12 lg:w-5/12 justify-center'>
                    <img src={amp} alt='AMP & IgG size relationship' className='w-auto md:float-left'></img>
                </div>
        </section>
    </div>

    </>
);

export default Home;