import React from "react";
import ampstat from '../../assets/amp-surface-stat.png';
import ContactForm from "../Form";

const Contact = () => (
    <section className='bg-gradient-r font-primary text-cyannarv-500'>
        <div className='container flex flex-wrap max-w-screen-2xl w-full h-full lg:h-screen md:h-auto m-auto p-8 pt-32 pb-20 lg:content-center'>
            <div className='flex w-2/5 md:w-3/12 lg:w-2/12 h-auto md:max-h-36 lg:max-h-64 lg:max-h-80 md:mt-2 lg:mt-14 lg:mr-12 md:pl-10 md:px-0 justify-center'>
                <img src={ampstat} alt='AMP surface & VGEF' className='w-auto'></img>
            </div>
            <div className='md:flex-1 w-3/5 md:w-3/12 lg:w-4/12 h-auto mt-0 md:mt-2 lg:mt-14 pl-5 md:px-5 lg:px-10'>
                <p className='w-full sec-name pb-2'>FIND US AT</p>
                <p className=''>Narval<br/>2261 Market Street STE 5414<br/>San Francisco, CA<br/>94114<br/></p>
            </div>
            <div className='w-full md:w-6/12 mt-20 md:mt-2 lg:mt-14 md:pl-5 lg:px-10'>
                <p className='w-full sec-name pb-2'>SEND US A MESSAGE</p>
                <ContactForm/>
            </div>
        </div>
    </section>
);

export default Contact;
