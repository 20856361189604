import "../../styles/App.css";
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import NavbarNarv from '../Navbar';
import Home from '../Home';
import Technology from "../Technology";
import Contact from '../Contact';
import Footer from '../Footer';


function App() {
    return (
        <>
        <BrowserRouter>
            <NavbarNarv/>
                <Routes>
                    <Route exact path='/' element={<Home/>}/>
                    <Route path='/technology' element={<Technology/>}/>
                    <Route path='/contact' element={<Contact/>}/>
                    <Route path='*' element={<Navigate replace to='/'/>}/>
                </Routes>
            <Footer/>
        </BrowserRouter>
        </>
    );
}

export default App;
