import React from 'react';
import ampVideo from '../../assets/amp-surface.mp4';
import PipelineTabs from '../Pipeline/indes';
import targetIcon from '../../assets/target-icon.svg';
import heatIcon from '../../assets/heat-icon.svg';
import immuneIcon from '../../assets/immune-icon.svg';

const Technology = () => (
    <section className=''>
        <div className='container flex flex-wrap max-w-screen-2xl md:flex-row-reverse w-full h-full lg:h-screen md:h-auto m-auto
        p-8 lg:content-center'>
            <div className='md:flex-1 md:w-3/5 lg:w-6/12 h-auto mt-20 md:pl-5 lg:p-12'>
                <h2 className='my-2 pt-5 display1-text'>Disrupting antibody drugs</h2>
                <p className='my-5 parr-text lg:mt-10'>
                Antibody drugs need to be dosed into the bloodstream at high doses to secure reaching the target disease site, which
                increases the risk of systemic side effects and treatment cost.</p>
            </div>
            <div className='flex md:w-3/5 lg:w-6/12 md:max-h-96 lg:max-h-full mt-4 md:mt-28 md:pl-10 lg:pr-10
            sm:px-28 md:px-0 justify-center'>
                <video src={ampVideo} autoPlay loop muted className='w-auto'></video>
            </div>
        </div>

        <div className='bg-gradient-b flex lg:h-screen lg:content-center'>
            <div className='container flex flex-wrap max-w-screen-2xl w-full md:w-11/12 h-full md:h-auto m-auto p-8'>
                <p className='w-full sec-name mt-10 pb-2 lg:mb-2 title-text'>Antibody Mimetic Proteins (AMP)</p>
                <div className='py-10 md:py-0 lg:py-12'>
                    <p className='md:w-3/4 md:my-10 lg:m-auto lg:mb-0 parr-text'>Most diseases could be treated more effectively with antibody drugs
                    delivered only to the target disease site.</p>
                    <h3 className='md:w-3/4 lg:m-auto pt-3 lg:mt-8 pb-16 highlighted-text'><strong className='text-cyannarv-500'>
                    Our AMP technology was created to unlock unconventional antibody drug applications</strong> replacing monoclonal antibodies.
                    </h3>
                </div>
            </div>
        </div>

        <div className='bg-white'>
            <div className='container flex flex-wrap max-w-screen-2xl w-full md:w-11/12 h-screen md:h-136 lg:h-screen m-auto p-8 place-content-center text-cyannarv-500'>
                <p className='md:w-3/4 mt-3 mb-10 font-bold text-3xl lg:text-5xl md:text-center leading-10 lg:leading-relaxed tracking-wide'>
                <strong className='text-cyannarv-500'>
                AMP are 40 times smaller</strong> than monoclonal antibodies, and
                <strong className='text-cyannarv-500'> can cross tissue barriers in the body.</strong></p>
            </div>
        </div>

        <div className='bg-gradient-t'>
            <div className='container flex flex-wrap max-w-screen-2xl w-full md:w-11/12 h-auto lg:h-screen m-auto p-8 pt-16
            place-content-center lg:justify-around'>
                <p className='w-full sec-name pb-2 lg:mb-2 title-text'>AMP Advantages</p>

                <div className='w-full md:w-4/12 lg:w-3/12 h-auto lg:h-96 lg:mb-10 p-7 md:p-2 text-center'>
                    <img src={heatIcon} alt='AMP surface & VGEF' className='w-auto m-10'></img>
                    <p className='my-5 md:my-10 parr-text font-semibold md:font-normal'>Heat resistant</p>
                </div>

                <div className='w-full md:w-4/12 lg:w-3/12 h-auto lg:h-96 lg:mb-10 p-7 md:p-2 text-center'>
                    <img src={targetIcon} alt='AMP surface & VGEF' className='w-auto m-10'></img>
                    <p className='my-5 md:my-10 parr-text font-semibold md:font-normal'>Can target hard to reach antigens</p>
                </div>

                <div className='w-full md:w-4/12 lg:w-3/12 h-auto lg:h-96 lg:mb-10 p-7 md:p-2 text-center'>
                    <img src={immuneIcon} alt='AMP surface & VGEF' className='w-auto m-10'></img>
                    <p className='my-5 md:my-10 parr-text font-semibold md:font-normal'>Limited immune reaction</p>
                </div>
            </div>
        </div>

        <div className='bg-gradient-b'>
            <div className='container flex flex-wrap max-w-screen-2xl w-full md:w-11/12 h-auto lg:h-screen m-auto p-8 pt-16 lg:pt-20 lg:content-center'>
                    <p className='w-full sec-name lg:mb-2 pb-2 title-text'>Development Pipeline</p>
                    <p className='lg:w-9/12 my-5 md:mt-10 md:mb-3 parr-text'> </p>
                    <PipelineTabs/>
            </div>
        </div>
    </section>
);

export default Technology;
