import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NarvalLogo from '../../assets/narval.svg';
import MenuIcon from '../../assets/icon-menu.svg'

const NavbarNarv = () => {
        let NavLinks = [
            {name:'TECHNOLOGY', link: '/technology'},
            {name:'CONTACT', link:'/contact'},
        ];
        let [open, setOpen]=useState(false);

        const scrollUp = () => {
            window.scrollTo(0, 0);
        };

    return(
        <div className='w-full fixed top-0 left-0 absolute'>
            <div className='md:flex items-center justify-between bg-white py-4 md:px-10 px-7'>

                <div className='font-chivo font-light text-lg cursor-pointer flex items-center'>
                    <Link to='/' onClick={scrollUp}>
                        <span className='mr-1 pt-2'>
                            <img className='h-7' src={NarvalLogo} alt='Narval logo'></img>
                        </span>
                    </Link>
                </div>

                <div onClick={()=>setOpen(!open)}>
                    <img src={MenuIcon} alt='menu-icon'
                        name={open ? 'close':'menu'}
                        className='h-7 absolute right-8 top-6 cursor-pointer md:hidden'>
                    </img>
                </div>

                <ul className={`md:flex items-center md:pb-0 pb-12 absolute md:static bg-white
                    md:z-auto z[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-200 ease-in
                    ${open ? 'top-19 opacity-100':'top[-490px]'} md:opacity-100 opacity-0`}>
                    {
                        NavLinks.map((link)=>(
                            <li key={link.name} className='md:ml-8 md:my-0 my-7'>
                                <Link to={link.link} onClick={scrollUp}>
                                    <p className='text-zinc hover:text-cyannarv-500 hover:font-semibold duration-500'>
                                    {link.name}</p>
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
};

export default NavbarNarv;